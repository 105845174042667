<template>
  <monthly-status
    v-if="sumsAreReady && expensesAreReady"
    :invoiceSums="invoiceSums"
    :charges="expenses"
    :options="options"
  ></monthly-status>
</template>

<script>
import MonthlyStatus from "../../components/Charts/Overview/MonthlyStatus";
import { mapActions, mapState } from "vuex";
import mixins from "../../mixins/mixins";

export default {
  mixins: [mixins],

  props: ["fontFamily"],

  components: {
    MonthlyStatus,
  },

  data(vm) {
    return {
      self: this,
      sumsAreReady: false,
      expensesAreReady: false,
      invoiceSums: [],
      expenses: [],
      options: {
        title: {
          fontFamily: this.fontFamily,
          display: false,
          text: `Tulot / Kulut ${new Date().getFullYear()}`,
          fontSize: 15,
          fontColor: "#6e707e",
        },
        legend: {
          display: true,
          labels: {
            fontFamily: this.fontFamily,
            fontColor: "#6e707e",
            fontSize: 15,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontFamily: this.fontFamily,
                beginAtZero: true,
                fontSize: 14,
                callback: function (value) {
                  return value + " €";
                },
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontFamily: this.fontFamily,
                fontSize: 13,
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          bodyFontFamily: this.fontFamily,
          titleFontFamily: this.fontFamily,
          backgroundColor: "#ffffff",
          bodyFontColor: "#858796",
          titleMarginBottom: 7,
          titleFontColor: "#6e707e",
          bodyFontSize: 15,
          titleFontSize: 17,
          borderColor: "#aadfeb",
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          intersect: false,
          mode: "index",
          caretPadding: 10,
          callbacks: {
            label: function (tooltipItem, data) {
              const incomes = data.datasets[0].data[tooltipItem.index];
              const costs = data.datasets[1].data[tooltipItem.index];
              const difference = incomes - costs;

              let displayOnce = true;
              if (tooltipItem.datasetIndex === 0 && displayOnce) {
                displayOnce = false;
                return `Vuokrat: ${vm.self.formatCurrency(
                  incomes
                )} \n Kulut: ${vm.self.formatCurrency(costs)} \n Tulo: ${vm.self.formatCurrency(
                  difference
                )}`;
              }
            },
          },
        },
      },
    };
  },

  computed: {
    ...mapState("statistics", ["monthlyInvoiceData", "charges"]),
  },

  watch: {
    monthlyInvoiceData() {
      this.invoiceSums = this.formatInvoiceData(this.monthlyInvoiceData);
      this.sumsAreReady = true;
    },

    charges() {
      this.expenses = this.formatExpensesData(this.charges);
      this.expensesAreReady = true;
      this.$store.state.statistics.monthlyStatusIsLoading = false;
    },
  },

  async created() {
    this.$store.state.statistics.monthlyStatusIsLoading = true;
    await this.getMonthlyInvoicing();
    await this.getMonthlyExpenses();
  },

  methods: {
    ...mapActions("statistics", ["getMonthlyInvoicing", "getMonthlyExpenses"]),

    formatInvoiceData(data) {
      let sums = [];

      for (let i = 1; i < 13; i++) {
        sums.push(0);
        data.forEach((el) => {
          if (el._id == i) {
            sums.pop();
            sums.push(+(Math.round(el.totalAmount * 100) / 100).toFixed(2));
          }
        });
      }

      return sums;
    },

    formatExpensesData(data) {
      const water = this.calcTotalCharges({ ...data }, "currentWaterCharges", "oldWaterCharges");
      const maintenance = this.calcTotalCharges(
        { ...data },
        "currentMaintenanceCharges",
        "oldMaintenanceCharges"
      );

      const loan = this.calcTotalLoanPayments({ ...data });

      let sums = [];
      for (let i = 0; i < 12; i++) {
        sums.push(+(water[i] + maintenance[i] + loan[i]).toFixed(2));
      }

      return sums;
    },

    calcTotalCharges(data, current, old) {
      const thisYear = new Date().getFullYear();
      let currentCharges = [...data.maintenanceCharges[0][current]];
      let oldCharges = [...data.maintenanceCharges[0][old]];

      // Return default if no values
      if (currentCharges.length === 0 && oldCharges.length === 0) {
        return Array(12).fill(0);
      }

      let priceObj = {};

      // Old amounts
      oldCharges.forEach((el) => {
        let months = Array(12).fill(null);

        el.items.forEach((item) => {
          if (item.year == thisYear) {
            for (let i = 0; i < item.month; i++) {
              if (months[i] == null) {
                months[i] = item.amount;
              }
            }
          } else if (item.year > thisYear) {
            for (let i = 0; i < 12; i++) {
              if (months[i] == null) {
                months[i] = item.amount;
              }
            }
          }
        });

        // Add months to obj
        priceObj[el._id] = months;
      });

      // Current amounts
      currentCharges.forEach((el) => {
        // Get current amount
        const currentAmount = el.amount == null ? 0 : el.amount;
        const apartmentId = el._id;

        if (apartmentId in priceObj) {
          priceObj[apartmentId] = priceObj[apartmentId].map((month) => {
            if (month == null) {
              month = currentAmount;
            }
            return month;
          });
        } else {
          priceObj[apartmentId] = Array(12).fill(currentAmount);
        }
      });

      // Calc totalsum

      const finalSums = Array(12).fill(0);

      for (const key in priceObj) {
        for (let i = 0; i < 12; i++) {
          finalSums[i] += priceObj[key][i];
        }
      }

      return finalSums;
    },

    calcTotalLoanPayments(data) {
      const thisYear = new Date().getFullYear();
      let currentLoans = [...data.maintenanceCharges[0].currentLoans];
      let oldLoans = [...data.maintenanceCharges[0].oldLoans];

      if (oldLoans.length === 0 && currentLoans.length === 0) {
        return Array(12).fill(0);
      }

      let priceObj = {};

      // Old loans
      oldLoans.forEach((el) => {
        const apartmentId = String(el._id);
        let months = Array(12).fill(null);

        el.items.forEach((item) => {
          if (item.year == thisYear) {
            for (let i = 0; i < item.month; i++) {
              if (months[i] == null) {
                months[i] = [
                  {
                    amount: item.amount,
                    condominiumLoanId: String(item.condominiumLoanId),
                  },
                ];
              } else {
                const found = months[i].findIndex(
                  (elem) => elem.condominiumLoanId == String(item.condominiumLoanId)
                );

                if (found == -1) {
                  months[i].push({
                    amount: item.amount,
                    condominiumLoanId: String(item.condominiumLoanId),
                  });
                }
              }
            }
          } else if (item.year > thisYear) {
            for (let i = 0; i < 12; i++) {
              if (months[i] == null) {
                months[i] = [
                  { amount: item.amount, condominiumLoanId: String(item.condominiumLoanId) },
                ];
              } else {
                const found = months[i].findIndex(
                  (elem) => elem.condominiumLoanId == String(item.condominiumLoanId)
                );

                if (found == -1) {
                  months[i].push({
                    amount: item.amount,
                    condominiumLoanId: String(item.condominiumLoanId),
                  });
                }
              }
            }
          }
        });

        // Add months to obj
        priceObj[apartmentId] = months;
      });

      // Current amounts
      currentLoans.forEach((el) => {
        const apartmentId = String(el._id);
        const currentElem = {
          amount: el.amount,
          condominiumLoanId: String(el.condominiumLoanId),
          endDate: el.endDate,
        };

        if (apartmentId in priceObj) {
          priceObj[apartmentId].forEach((month, index) => {
            if (month == null) {
              const add = this.addBasedOnDate(currentElem, index, thisYear);
              if (add) priceObj[apartmentId][index] = [currentElem];
            } else {
              const found = month.findIndex(
                (elem) => elem.condominiumLoanId == String(currentElem.condominiumLoanId)
              );

              if (found == -1) {
                const add = this.addBasedOnDate(currentElem, index, thisYear);
                if (add) priceObj[apartmentId][index].push(currentElem);
              }
            }
          });
        } else {
          const array = Array(12);
          const zeroElem = { ...currentElem, amount: 0 };

          for (let i = 0; i < 12; i++) {
            const add = this.addBasedOnDate(currentElem, i, thisYear);
            if (add) {
              array[i] = [currentElem];
            } else {
              array[i] = [zeroElem];
            }
          }

          priceObj[apartmentId] = array;
        }
      });

      const finalSums = Array(12).fill(0);

      for (let i = 0; i < 12; i++) {
        let total = 0;

        for (const key in priceObj) {
          if (Array.isArray(priceObj[key][i])) {
            priceObj[key][i].forEach((el) => {
              total += el.amount;
            });
          }
        }

        finalSums[i] = total;
      }

      return finalSums;
    },

    // HELPERS
    addBasedOnDate(elem, index, thisYear) {
      if (elem.endDate) {
        const elemYear = new Date(elem.endDate).getFullYear();
        const elemMonth = new Date(elem.endDate).getMonth();

        if (elemYear > thisYear || (elemYear == thisYear && index <= elemMonth)) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
};
</script>

<style></style>
