<template>
  <div class="min-w-0 w-full">
    <v-row dense>
      <!-- Tunnusluvut -->
      <v-col cols="12" lg="6">
        <v-card class="pa-2 h-full">
          <v-card-title>Salkku</v-card-title>

          <v-card-text v-if="accountStatisticsLoading" class="text-center">
            <v-progress-circular
              class="justify-center"
              :size="60"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-card-text>

          <v-card-text v-show="!accountStatisticsLoading">
            <Investments></Investments>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Talous -->
      <v-col cols="12" lg="6">
        <v-card class="pa-2 h-full">
          <v-card-title>Talous</v-card-title>

          <v-card-text v-if="invoiceStatisticIsLoading" class="text-center">
            <v-progress-circular
              class="justify-center"
              :size="60"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-card-text>

          <v-card-text v-show="!invoiceStatisticIsLoading">
            <invoice-statistics></invoice-statistics>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Basic statistics -->
    <v-row dense>
      <v-col cols="12">
        <v-card class="pa-2 h-full">
          <v-card-title>Yleiset</v-card-title>

          <v-card-text v-if="basicStatisticIsLoading" class="text-center">
            <v-progress-circular
              class="justify-center"
              :size="60"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-card-text>

          <v-card-text v-show="!basicStatisticIsLoading">
            <basic-statistics></basic-statistics>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense>
      <!-- Revenues and expenses -->
      <v-col cols="12" lg="6">
        <v-card class="pa-2 h-full">
          <v-card-title style="padding-bottom: 5px">Tulot | Kulut {{ thisYear }}</v-card-title>
          <v-card-text>Vuokrat | Hoitovastikkeet & Vesimaksut & Rahoitusvastikkeet</v-card-text>
          <v-card-text class="text-center">
            <v-progress-circular
              v-if="monthlyStatusIsLoading"
              class="justify-center"
              :size="60"
              color="primary"
              indeterminate
            ></v-progress-circular>

            <revenues-and-expenses
              v-show="!monthlyStatusIsLoading && getFontFamily"
              class="mt-2"
              :fontFamily="getFontFamily"
            ></revenues-and-expenses>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Expired invoices -->
      <v-col cols="12" lg="6">
        <v-card class="pa-2 h-full">
          <v-card-title>Erääntyneet laskut</v-card-title>
          <overdue-invoices class="mt-2"></overdue-invoices>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BasicStatistics from "../../components/Overview/BasicStatistics";
import InvoiceStatistics from "../../components/Overview/InvoiceStatistics";
import OverdueInvoices from "../../components/Overview/OverdueInvoices";
import RevenuesAndExpenses from "../../components/Overview/RevenuesAndExpenses";
import Investments from "../../components/Overview/Investments";
import mixins from "../../mixins/mixins";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  title: "Yhteenveto",

  mixins: [mixins],

  components: {
    OverdueInvoices,
    RevenuesAndExpenses,
    BasicStatistics,
    InvoiceStatistics,
    Investments,
  },

  data() {
    return {
      thisYear: new Date().getFullYear(),
    };
  },

  async created() {
    try {
      this.$store.state.statistics.accountStatisticsLoading = true;
      await this.getStats();
      this.$store.state.statistics.accountStatisticsLoading = false;
    } catch (err) {
      this.showPopup(err, "error");
    }
  },

  computed: {
    ...mapState("statistics", [
      "monthlyStatusIsLoading",
      "basicStatisticIsLoading",
      "invoiceStatisticIsLoading",
      "investmentStatisticIsLoading",
      "accountStatisticsLoading",
    ]),
    ...mapGetters("account", ["getFontFamily"]),
  },

  methods: {
    ...mapActions("statistics", ["getStats"]),
  },
};
</script>

<style scoped></style>
